import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      const token = localStorage.getItem('authToken'); // Get the token from localStorage

      try {
        const response = await fetch('http://itexamsvouchers.com:4242/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the JWT token
          },
        });
        console.log(response);

        if (response.ok) {
          localStorage.removeItem('token'); // Remove token from localStorage
          navigate('/login'); // Redirect to login page
        } else {
          console.error('Logout failed');
        }
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    handleLogout();
  }, [navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
