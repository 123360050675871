import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Card.css';

interface Company {
  id: string;
  name: string;
  image: string;
}

interface Product {
  productId: string;    // Custom product ID
  productName: string;  // Custom product name
  companyId: string;    // Custom company ID
  categoryId: string;   // Custom category ID
}

const Home: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // Search term for products
  const [products, setProducts] = useState<Product[]>([]); // For storing search results (products)
  const [loading, setLoading] = useState(false); // Loading state for search
  const [error, setError] = useState<string | null>(null); // Error state for search
  const navigate = useNavigate(); // For navigation

  // Fetch companies on mount (for company listing)
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('http://itexamsvouchers.com:4242/companies');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  // Function to handle search input change and fetch product suggestions
  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    if (searchValue === '') {
      setProducts([]); // Clear suggestions if search is empty
    } else {
      try {
        setLoading(true);
        const response = await fetch(`http://itexamsvouchers.com:4242/products/search?q=${searchValue}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data); // Assuming the response is a list of products with companyId and categoryId
        setLoading(false);
      } catch (error) {
        setError('Error fetching products');
        setLoading(false);
        console.error('Error fetching products:', error);
      }
    }
  };

  // Function to handle clicking on a product suggestion
  const handleProductClick = (product: Product) => {
    navigate(`/company/${product.companyId}/category/${product.categoryId}/product/${product.productId}`); // Navigate to the product details page
  };

  return (
    <div>
      <h1 className="text-4xl font-bold p-4">Shop by Companies</h1>

      {/* Search Input */}
      <div className="p-4">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="p-2 border rounded w-full"
        />
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
      </div>

      {/* Product Suggestions Dropdown */}
      {products.length > 0 && (
        <ul className="border rounded p-2 mt-2 max-h-60 overflow-y-auto">
          {products.map((product) => (
            <li
              key={product.productId}
              className="cursor-pointer hover:bg-gray-200 p-2"
              onClick={() => handleProductClick(product)}
            >
              {product.productName} {/* Display product name */}
            </li>
          ))}
        </ul>
      )}

      {/* Company Listings (Always displayed) */}
      <ul className="card-list">
        {companies.map((company) => (
          <Link to={`/company/${company.id}`} className="text-blue-500 hover:underline" key={company.id}>
            <li className="card">
              <img src={company.image} alt={company.name} className="card-image" />
              {company.name}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default Home;
