import React from 'react';
import { useCart } from '../context/CartContext';
import { Link } from 'react-router-dom'; // Make sure you have React Router installed

const CartPage: React.FC = () => {
  const { cart, removeFromCart } = useCart();

  return (
    <div>
      <h1 className="text-4xl font-bold">Your Cart</h1>
      {cart.length === 0 ? (
        <p className="text-lg mt-4">Your cart is empty.</p>
      ) : (
        <div>
          <ul className="mt-6">
            {cart.map((item) => (
              <li key={item.id} className="mb-4 flex items-center border-b pb-4">
                <img width="100px" src={item.image} alt={item.name} className="mr-4" />
                <div>
                  <h2 className="text-xl">{item.name}</h2>
                  <p className="text-lg">
                    Price: ${typeof item.price === 'number' ? item.price.toFixed(2) : 'N/A'}
                  </p>
                  <p className="text-lg">Quantity: {item.quantity || 0}</p>
                  <button
                    className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                    onClick={() => removeFromCart(item.id)}
                  >
                    Remove from Cart
                  </button>
                </div>
              </li>
            ))}
          </ul>

          <Link to="/place-order">
            <button className="mt-4 px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-700">
              Continue to Place Order
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartPage;
