import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';

interface Category {
  id: string;
  name: string;
  image?: string;
}

const token = localStorage.getItem('token');

const ManageCategories: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/categories`);
      const data = await response.json();
      setCategories(data);
    };

    fetchCategories();
  }, [companyId]);

  const handleEdit = (category: Category) => {
    setSelectedCategory(category);
    setUpdatedName(category.name);
    setUpdatedImage(category.image || '');
    setShowModal(true);
  };

  const handleUpdateCategory = async () => {
    if (!selectedCategory) return;

    try {
      const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${selectedCategory.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
        body: JSON.stringify({ name: updatedName, image: updatedImage }),
      });

      if (response.ok) {
        setCategories(
          categories.map(category =>
            category.id === selectedCategory.id ? { ...category, name: updatedName, image: updatedImage } : category
          )
        );
        setShowModal(false);
      } else {
        console.error('Failed to update the category');
      }
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleDelete = async (categoryId: string) => {
    try {
      const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
      });
      if (response.ok) {
        setCategories(categories.filter(category => category.id !== categoryId));
      } else {
        console.error('Failed to delete the category');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <Button className="bi bi-arrow-left-circle-fill" onClick={handleBack}>
          <ArrowLeft display="inline" />
        </Button>
        <h2 className="ms-2">Manage Categories</h2>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category.id}>
              <td>
                <Link to={`/dashboard/manage-products/${companyId}/category/${category.id}`}>
                  {category.name}
                </Link>
              </td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(category)}>Edit</Button>
                <Button variant="danger" onClick={() => handleDelete(category.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                value={updatedName}
                onChange={e => setUpdatedName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Category Image URL</Form.Label>
              <Form.Control
                type="text"
                value={updatedImage}
                onChange={e => setUpdatedImage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleUpdateCategory}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageCategories;
