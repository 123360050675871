import React, { ChangeEvent, FormEvent } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';

interface RegisterState {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  country: string;
  phoneExtension: string;  // Derived from the country selection
}

class Register extends React.Component<{ onSubmit: (formData: RegisterState) => void }, RegisterState> {
  constructor(props: { onSubmit: (formData: RegisterState) => void }) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      country: '',
      phoneExtension: '',  // No separate input field for phone extension
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as Pick<RegisterState, keyof RegisterState>);
  }

  handleCountryChange(event: ChangeEvent<HTMLSelectElement>) {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    console.log(selectedOption);

    // Update both country and phoneExtension based on selection
    this.setState({
      country: selectedOption.getAttribute('data-country') || '',
      phoneExtension: selectedOption.getAttribute('data-code') || '',
    });
  }

  handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // Send the country, phoneNumber, and phoneExtension together
    this.props.onSubmit(this.state);
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <h2>Customer Information</h2>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridFirstname">
            <Form.Label>First Name *</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleChange}
              placeholder="First Name"
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastname">
            <Form.Label>Last Name *</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleChange}
              placeholder="Last Name"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Phone Number *</Form.Label>
            <Form.Control
              type="text"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onChange={this.handleChange}
              placeholder="Phone Number"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Email"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Country *</Form.Label>
            <Form.Select
              name="country"
              value={this.state.country}
              onChange={this.handleCountryChange}
              required
            >
              <option value="">Select a country</option>
              <option data-country="Afghanistan" data-code="+93" value="Afghanistan">Afghanistan (+93)</option>
<option data-country="Albania" data-code="+355" value="Albania">Albania (+355)</option>
<option data-country="Algeria" data-code="+213" value="Algeria">Algeria (+213)</option>
<option data-country="Andorra" data-code="+376" value="Andorra">Andorra (+376)</option>
<option data-country="Angola" data-code="+244" value="Angola">Angola (+244)</option>
<option data-country="Antigua and Barbuda" data-code="+1-268" value="Antigua and Barbuda">Antigua and Barbuda (+1-268)</option>
<option data-country="Argentina" data-code="+54" value="Argentina">Argentina (+54)</option>
<option data-country="Armenia" data-code="+374" value="Armenia">Armenia (+374)</option>
<option data-country="Australia" data-code="+61" value="Australia">Australia (+61)</option>
<option data-country="Austria" data-code="+43" value="Austria">Austria (+43)</option>
<option data-country="Azerbaijan" data-code="+994" value="Azerbaijan">Azerbaijan (+994)</option>
<option data-country="Bahamas" data-code="+1-242" value="Bahamas">Bahamas (+1-242)</option>
<option data-country="Bahrain" data-code="+973" value="Bahrain">Bahrain (+973)</option>
<option data-country="Bangladesh" data-code="+880" value="Bangladesh">Bangladesh (+880)</option>
<option data-country="Barbados" data-code="+1-246" value="Barbados">Barbados (+1-246)</option>
<option data-country="Belarus" data-code="+375" value="Belarus">Belarus (+375)</option>
<option data-country="Belgium" data-code="+32" value="Belgium">Belgium (+32)</option>
<option data-country="Belize" data-code="+501" value="Belize">Belize (+501)</option>
<option data-country="Benin" data-code="+229" value="Benin">Benin (+229)</option>
<option data-country="Bhutan" data-code="+975" value="Bhutan">Bhutan (+975)</option>
<option data-country="Bolivia" data-code="+591" value="Bolivia">Bolivia (+591)</option>
<option data-country="Bosnia and Herzegovina" data-code="+387" value="Bosnia and Herzegovina">Bosnia and Herzegovina (+387)</option>
<option data-country="Botswana" data-code="+267" value="Botswana">Botswana (+267)</option>
<option data-country="Brazil" data-code="+55" value="Brazil">Brazil (+55)</option>
<option data-country="Brunei" data-code="+673" value="Brunei">Brunei (+673)</option>
<option data-country="Bulgaria" data-code="+359" value="Bulgaria">Bulgaria (+359)</option>
<option data-country="Burkina Faso" data-code="+226" value="Burkina Faso">Burkina Faso (+226)</option>
<option data-country="Burundi" data-code="+257" value="Burundi">Burundi (+257)</option>
<option data-country="Cabo Verde" data-code="+238" value="Cabo Verde">Cabo Verde (+238)</option>
<option data-country="Cambodia" data-code="+855" value="Cambodia">Cambodia (+855)</option>
<option data-country="Cameroon" data-code="+237" value="Cameroon">Cameroon (+237)</option>
<option data-country="Canada" data-code="+1" value="Canada">Canada (+1)</option>
<option data-country="Central African Republic" data-code="+236" value="Central African Republic">Central African Republic (+236)</option>
<option data-country="Chad" data-code="+235" value="Chad">Chad (+235)</option>
<option data-country="Chile" data-code="+56" value="Chile">Chile (+56)</option>
<option data-country="China" data-code="+86" value="China">China (+86)</option>
<option data-country="Colombia" data-code="+57" value="Colombia">Colombia (+57)</option>
<option data-country="Comoros" data-code="+269" value="Comoros">Comoros (+269)</option>
<option data-country="Congo (Congo-Brazzaville)" data-code="+242" value="Congo">Congo (+242)</option>
<option data-country="Congo (DRC)" data-code="+243" value="Congo (DRC)">Congo (DRC) (+243)</option>
<option data-country="Costa Rica" data-code="+506" value="Costa Rica">Costa Rica (+506)</option>
<option data-country="Croatia" data-code="+385" value="Croatia">Croatia (+385)</option>
<option data-country="Cuba" data-code="+53" value="Cuba">Cuba (+53)</option>
<option data-country="Cyprus" data-code="+357" value="Cyprus">Cyprus (+357)</option>
<option data-country="Czech Republic" data-code="+420" value="Czech Republic">Czech Republic (+420)</option>
<option data-country="Denmark" data-code="+45" value="Denmark">Denmark (+45)</option>
<option data-country="Djibouti" data-code="+253" value="Djibouti">Djibouti (+253)</option>
<option data-country="Dominica" data-code="+1-767" value="Dominica">Dominica (+1-767)</option>
<option data-country="Dominican Republic" data-code="+1-809" value="Dominican Republic">Dominican Republic (+1-809)</option>
<option data-country="Ecuador" data-code="+593" value="Ecuador">Ecuador (+593)</option>
<option data-country="Egypt" data-code="+20" value="Egypt">Egypt (+20)</option>
<option data-country="El Salvador" data-code="+503" value="El Salvador">El Salvador (+503)</option>
<option data-country="Equatorial Guinea" data-code="+240" value="Equatorial Guinea">Equatorial Guinea (+240)</option>
<option data-country="Eritrea" data-code="+291" value="Eritrea">Eritrea (+291)</option>
<option data-country="Estonia" data-code="+372" value="Estonia">Estonia (+372)</option>
<option data-country="Eswatini" data-code="+268" value="Eswatini">Eswatini (+268)</option>
<option data-country="Ethiopia" data-code="+251" value="Ethiopia">Ethiopia (+251)</option>
<option data-country="Fiji" data-code="+679" value="Fiji">Fiji (+679)</option>
<option data-country="Finland" data-code="+358" value="Finland">Finland (+358)</option>
<option data-country="France" data-code="+33" value="France">France (+33)</option>
<option data-country="Gabon" data-code="+241" value="Gabon">Gabon (+241)</option>
<option data-country="Gambia" data-code="+220" value="Gambia">Gambia (+220)</option>
<option data-country="Georgia" data-code="+995" value="Georgia">Georgia (+995)</option>
<option data-country="Germany" data-code="+49" value="Germany">Germany (+49)</option>
<option data-country="Ghana" data-code="+233" value="Ghana">Ghana (+233)</option>
<option data-country="Greece" data-code="+30" value="Greece">Greece (+30)</option>
<option data-country="Grenada" data-code="+1-473" value="Grenada">Grenada (+1-473)</option>
<option data-country="Guatemala" data-code="+502" value="Guatemala">Guatemala (+502)</option>
<option data-country="Guinea" data-code="+224" value="Guinea">Guinea (+224)</option>
<option data-country="Guinea-Bissau" data-code="+245" value="Guinea-Bissau">Guinea-Bissau (+245)</option>
<option data-country="Guyana" data-code="+592" value="Guyana">Guyana (+592)</option>
<option data-country="Haiti" data-code="+509" value="Haiti">Haiti (+509)</option>
<option data-country="Honduras" data-code="+504" value="Honduras">Honduras (+504)</option>
<option data-country="Hungary" data-code="+36" value="Hungary">Hungary (+36)</option>
<option data-country="Iceland" data-code="+354" value="Iceland">Iceland (+354)</option>
<option data-country="India" data-code="+91" value="India">India (+91)</option>
<option data-country="Indonesia" data-code="+62" value="Indonesia">Indonesia (+62)</option>
<option data-country="Iran" data-code="+98" value="Iran">Iran (+98)</option>
<option data-country="Iraq" data-code="+964" value="Iraq">Iraq (+964)</option>
<option data-country="Ireland" data-code="+353" value="Ireland">Ireland (+353)</option>
<option data-country="Israel" data-code="+972" value="Israel">Israel (+972)</option>
<option data-country="Italy" data-code="+39" value="Italy">Italy (+39)</option>
<option data-country="Jamaica" data-code="+1-876" value="Jamaica">Jamaica (+1-876)</option>
<option data-country="Japan" data-code="+81" value="Japan">Japan (+81)</option>
<option data-country="Jordan" data-code="+962" value="Jordan">Jordan (+962)</option>
<option data-country="Kazakhstan" data-code="+7" value="Kazakhstan">Kazakhstan (+7)</option>
<option data-country="Kenya" data-code="+254" value="Kenya">Kenya (+254)</option>
<option data-country="Kiribati" data-code="+686" value="Kiribati">Kiribati (+686)</option>
<option data-country="Kuwait" data-code="+965" value="Kuwait">Kuwait (+965)</option>
<option data-country="Kyrgyzstan" data-code="+996" value="Kyrgyzstan">Kyrgyzstan (+996)</option>
<option data-country="Laos" data-code="+856" value="Laos">Laos (+856)</option>
<option data-country="Latvia" data-code="+371" value="Latvia">Latvia (+371)</option>
<option data-country="Lebanon" data-code="+961" value="Lebanon">Lebanon (+961)</option>
<option data-country="Lesotho" data-code="+266" value="Lesotho">Lesotho (+266)</option>
<option data-country="Liberia" data-code="+231" value="Liberia">Liberia (+231)</option>
<option data-country="Libya" data-code="+218" value="Libya">Libya (+218)</option>
<option data-country="Liechtenstein" data-code="+423" value="Liechtenstein">Liechtenstein (+423)</option>
<option data-country="Lithuania" data-code="+370" value="Lithuania">Lithuania (+370)</option>
<option data-country="Luxembourg" data-code="+352" value="Luxembourg">Luxembourg (+352)</option>
<option data-country="Madagascar" data-code="+261" value="Madagascar">Madagascar (+261)</option>
<option data-country="Malawi" data-code="+265" value="Malawi">Malawi (+265)</option>
<option data-country="Malaysia" data-code="+60" value="Malaysia">Malaysia (+60)</option>
<option data-country="Maldives" data-code="+960" value="Maldives">Maldives (+960)</option>
<option data-country="Mali" data-code="+223" value="Mali">Mali (+223)</option>
<option data-country="Malta" data-code="+356" value="Malta">Malta (+356)</option>
<option data-country="Marshall Islands" data-code="+692" value="Marshall Islands">Marshall Islands (+692)</option>
<option data-country="Mauritania" data-code="+222" value="Mauritania">Mauritania (+222)</option>
<option data-country="Mauritius" data-code="+230" value="Mauritius">Mauritius (+230)</option>
<option data-country="Mexico" data-code="+52" value="Mexico">Mexico (+52)</option>
<option data-country="Micronesia" data-code="+691" value="Micronesia">Micronesia (+691)</option>
<option data-country="Moldova" data-code="+373" value="Moldova">Moldova (+373)</option>
<option data-country="Monaco" data-code="+377" value="Monaco">Monaco (+377)</option>
<option data-country="Mongolia" data-code="+976" value="Mongolia">Mongolia (+976)</option>
<option data-country="Montenegro" data-code="+382" value="Montenegro">Montenegro (+382)</option>
<option data-country="Morocco" data-code="+212" value="Morocco">Morocco (+212)</option>
<option data-country="Mozambique" data-code="+258" value="Mozambique">Mozambique (+258)</option>
<option data-country="Myanmar" data-code="+95" value="Myanmar">Myanmar (+95)</option>
<option data-country="Namibia" data-code="+264" value="Namibia">Namibia (+264)</option>
<option data-country="Nauru" data-code="+674" value="Nauru">Nauru (+674)</option>
<option data-country="Nepal" data-code="+977" value="Nepal">Nepal (+977)</option>
<option data-country="Netherlands" data-code="+31" value="Netherlands">Netherlands (+31)</option>
<option data-country="New Zealand" data-code="+64" value="New Zealand">New Zealand (+64)</option>
<option data-country="Nicaragua" data-code="+505" value="Nicaragua">Nicaragua (+505)</option>
<option data-country="Niger" data-code="+227" value="Niger">Niger (+227)</option>
<option data-country="Nigeria" data-code="+234" value="Nigeria">Nigeria (+234)</option>
<option data-country="North Macedonia" data-code="+389" value="North Macedonia">North Macedonia (+389)</option>
<option data-country="Norway" data-code="+47" value="Norway">Norway (+47)</option>
<option data-country="Oman" data-code="+968" value="Oman">Oman (+968)</option>
<option data-country="Pakistan" data-code="+92" value="Pakistan">Pakistan (+92)</option>
<option data-country="Palau" data-code="+680" value="Palau">Palau (+680)</option>
<option data-country="Panama" data-code="+507" value="Panama">Panama (+507)</option>
<option data-country="Papua New Guinea" data-code="+675" value="Papua New Guinea">Papua New Guinea (+675)</option>
<option data-country="Paraguay" data-code="+595" value="Paraguay">Paraguay (+595)</option>
<option data-country="Peru" data-code="+51" value="Peru">Peru (+51)</option>
<option data-country="Philippines" data-code="+63" value="Philippines">Philippines (+63)</option>
<option data-country="Poland" data-code="+48" value="Poland">Poland (+48)</option>
<option data-country="Portugal" data-code="+351" value="Portugal">Portugal (+351)</option>
<option data-country="Qatar" data-code="+974" value="Qatar">Qatar (+974)</option>
<option data-country="Romania" data-code="+40" value="Romania">Romania (+40)</option>
<option data-country="Russia" data-code="+7" value="Russia">Russia (+7)</option>
<option data-country="Rwanda" data-code="+250" value="Rwanda">Rwanda (+250)</option>
<option data-country="Saint Kitts and Nevis" data-code="+1-869" value="Saint Kitts and Nevis">Saint Kitts and Nevis (+1-869)</option>
<option data-country="Saint Lucia" data-code="+1-758" value="Saint Lucia">Saint Lucia (+1-758)</option>
<option data-country="Saint Vincent and the Grenadines" data-code="+1-784" value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines (+1-784)</option>
<option data-country="Samoa" data-code="+685" value="Samoa">Samoa (+685)</option>
<option data-country="San Marino" data-code="+378" value="San Marino">San Marino (+378)</option>
<option data-country="Sao Tome and Principe" data-code="+239" value="Sao Tome and Principe">Sao Tome and Principe (+239)</option>
<option data-country="Saudi Arabia" data-code="+966" value="Saudi Arabia">Saudi Arabia (+966)</option>
<option data-country="Senegal" data-code="+221" value="Senegal">Senegal (+221)</option>
<option data-country="Serbia" data-code="+381" value="Serbia">Serbia (+381)</option>
<option data-country="Seychelles" data-code="+248" value="Seychelles">Seychelles (+248)</option>
<option data-country="Sierra Leone" data-code="+232" value="Sierra Leone">Sierra Leone (+232)</option>
<option data-country="Singapore" data-code="+65" value="Singapore">Singapore (+65)</option>
<option data-country="Slovakia" data-code="+421" value="Slovakia">Slovakia (+421)</option>
<option data-country="Slovenia" data-code="+386" value="Slovenia">Slovenia (+386)</option>
<option data-country="Solomon Islands" data-code="+677" value="Solomon Islands">Solomon Islands (+677)</option>
<option data-country="Somalia" data-code="+252" value="Somalia">Somalia (+252)</option>
<option data-country="South Africa" data-code="+27" value="South Africa">South Africa (+27)</option>
<option data-country="South Korea" data-code="+82" value="South Korea">South Korea (+82)</option>
<option data-country="South Sudan" data-code="+211" value="South Sudan">South Sudan (+211)</option>
<option data-country="Spain" data-code="+34" value="Spain">Spain (+34)</option>
<option data-country="Sri Lanka" data-code="+94" value="Sri Lanka">Sri Lanka (+94)</option>
<option data-country="Sudan" data-code="+249" value="Sudan">Sudan (+249)</option>
<option data-country="Suriname" data-code="+597" value="Suriname">Suriname (+597)</option>
<option data-country="Sweden" data-code="+46" value="Sweden">Sweden (+46)</option>
<option data-country="Switzerland" data-code="+41" value="Switzerland">Switzerland (+41)</option>
<option data-country="Syria" data-code="+963" value="Syria">Syria (+963)</option>
<option data-country="Taiwan" data-code="+886" value="Taiwan">Taiwan (+886)</option>
<option data-country="Tajikistan" data-code="+992" value="Tajikistan">Tajikistan (+992)</option>
<option data-country="Tanzania" data-code="+255" value="Tanzania">Tanzania (+255)</option>
<option data-country="Thailand" data-code="+66" value="Thailand">Thailand (+66)</option>
<option data-country="Timor-Leste" data-code="+670" value="Timor-Leste">Timor-Leste (+670)</option>
<option data-country="Togo" data-code="+228" value="Togo">Togo (+228)</option>
<option data-country="Tonga" data-code="+676" value="Tonga">Tonga (+676)</option>
<option data-country="Trinidad and Tobago" data-code="+1-868" value="Trinidad and Tobago">Trinidad and Tobago (+1-868)</option>
<option data-country="Tunisia" data-code="+216" value="Tunisia">Tunisia (+216)</option>
<option data-country="Turkey" data-code="+90" value="Turkey">Turkey (+90)</option>
<option data-country="Turkmenistan" data-code="+993" value="Turkmenistan">Turkmenistan (+993)</option>
<option data-country="Tuvalu" data-code="+688" value="Tuvalu">Tuvalu (+688)</option>
<option data-country="Uganda" data-code="+256" value="Uganda">Uganda (+256)</option>
<option data-country="Ukraine" data-code="+380" value="Ukraine">Ukraine (+380)</option>
<option data-country="United Arab Emirates" data-code="+971" value="United Arab Emirates">United Arab Emirates (+971)</option>
<option data-country="United Kingdom" data-code="+44" value="United Kingdom">United Kingdom (+44)</option>
<option data-country="United States" data-code="+1" value="United States">United States (+1)</option>
<option data-country="Uruguay" data-code="+598" value="Uruguay">Uruguay (+598)</option>
<option data-country="Uzbekistan" data-code="+998" value="Uzbekistan">Uzbekistan (+998)</option>
<option data-country="Vanuatu" data-code="+678" value="Vanuatu">Vanuatu (+678)</option>
<option data-country="Vatican City" data-code="+379" value="Vatican City">Vatican City (+379)</option>
<option data-country="Venezuela" data-code="+58" value="Venezuela">Venezuela (+58)</option>
<option data-country="Vietnam" data-code="+84" value="Vietnam">Vietnam (+84)</option>
<option data-country="Yemen" data-code="+967" value="Yemen">Yemen (+967)</option>
<option data-country="Zambia" data-code="+260" value="Zambia">Zambia (+260)</option>
<option data-country="Zimbabwe" data-code="+263" value="Zimbabwe">Zimbabwe (+263)</option>


            </Form.Select>
          </Form.Group>
        </Row>

        <Button className="mt-3" variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    );
  }
}

export default Register;
