import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';

interface Company {
  id: string;
  name: string;
  image?: string;
}

const ManageProducts: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await fetch('http://itexamsvouchers.com:4242/companies'); // Update URL as necessary
      const data = await response.json();
      setCompanies(data);
    };

    fetchCompanies();
  }, []);

  const handleEdit = (company: Company) => {
    setSelectedCompany(company);
    setUpdatedName(company.name);
    setUpdatedImage(company.image || '');
    setShowModal(true);
  };

  const handleUpdateCompany = async () => {
    if (!selectedCompany) return;

    try {
      const response = await fetch(`http://itexamsvouchers.com:4242/company/${selectedCompany.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
        body: JSON.stringify({ name: updatedName, image: updatedImage }),
      });

      if (response.ok) {
        setCompanies(
          companies.map(company =>
            company.id === selectedCompany.id ? { ...company, name: updatedName, image: updatedImage } : company
          )
        );
        setShowModal(false);
      } else {
        console.error('Failed to update the company');
      }
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  const handleDelete = async (companyId: string) => {
    try {
      const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setCompanies(companies.filter(company => company.id !== companyId));
      } else {
        console.error('Failed to delete the company');
      }
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <Button className="bi bi-arrow-left-circle-fill" onClick={handleBack}>
          <ArrowLeft display="inline" />
        </Button>
        <h2 className="ms-2">Manage Companies</h2>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies.map(company => (
            <tr key={company.id}>
              <td>
                <Link to={`/dashboard/manage-products/${company.id}`}>{company.name}</Link>
              </td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(company)}>Edit</Button>
                <Button variant="danger" onClick={() => handleDelete(company.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                value={updatedName}
                onChange={e => setUpdatedName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Company Image URL</Form.Label>
              <Form.Control
                type="text"
                value={updatedImage}
                onChange={e => setUpdatedImage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleUpdateCompany}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageProducts;
