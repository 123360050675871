// src/pages/Dashboard.tsx
import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Dashboard: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="sidebar">
          <Nav className="flex-column">
            <Nav.Link as={Link} to="/dashboard/manage-products">Manage Products</Nav.Link>
            <Nav.Link as={Link} to="/dashboard/manage-orders">Manage Orders</Nav.Link>
          </Nav>
        </Col>
        <Col md={10}>
          {/* Content will be rendered here based on the route */}
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
