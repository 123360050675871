import React from 'react';
import Register from './Register';  // Adjust path based on your project structure
import { useCart } from '../../context/CartContext'; // Import the useCart hook to access cart data

const PlaceOrder: React.FC = () => {
  const { cart, clearCart } = useCart();  // Get the cart data and clearCart function from context

  const handleRegisterSubmit = async (registerData: any) => {
    const payload = {
      ...registerData,
      cart,
    };

    try {
      const response = await fetch('http://itexamsvouchers.com:4242/placeOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Order Success:', data);
        alert('Order placed successfully!');
        clearCart();  // Clear the cart after successful order placement
      } else {
        alert('Error placing order: ' + response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while placing the order');
    }
  };

  return (
    <div>
      
    <h1>Place Order</h1>
        {/* Display cart items */}
          <h2>Your Exams</h2>
          <div className="container">
  {cart.map((product) => {
    const subtotal = product.price * product.quantity; // Calculate subtotal
    return (
      <div className="row align-items-center mb-3 border-bottom py-2" key={product.id}>
        <div className="col-12 col-md-2 justify-content-center d-flex">
          <img src={product.image} alt={product.name} className="img-fluid" style={{ maxHeight: '100px' }} />
        </div>
        <div className="col-12 col-md-5">
          <h5 className="mb-1">{product.name}</h5>
          <p className="mb-0">Price: ${product.price}</p>
        </div>
        <div className="col-12 col-md-2 d-flex ">
          <span className="h5 mb-0">Quantity: {product.quantity}</span>
        </div>
        <div className="col-12 col-md-2 d-flex">
          <h5 className="mb-0">Subtotal: ${subtotal.toFixed(2)}</h5>
        </div>
      </div>
    );
  })}
</div>




          {/* Display Register component and pass the submission handler */}
          <Register onSubmit={handleRegisterSubmit} />

      
    </div>
  );
};

export default PlaceOrder;
