// src/pages/SubcategoryPage.tsx
import React, {useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import { Product } from '../types';

const SubcategoryPage: React.FC = () => {
  const { companyId, categoryId } = useParams<{ companyId: string; categoryId: string }>();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/products`);
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [companyId, categoryId]);

  if (loading) {
    return <h1 className="text-2xl">Loading...</h1>;
  }

  if (products.length === 0) {
    return <h1 className="text-2xl">No products found</h1>;
  }

  return (
    <div>
      <h2 className="text-3xl font-bold mt-6">Products</h2>
      <ul className='card-list'>
        {products.map(product => (
          <Link to={`/company/${companyId}/category/${categoryId}/product/${product.id}`} key={product.id} className="hover:underline">
            <li className="text-lg card">
              <img src={product.image} alt={product.name} className="card-image" />
              {product.name}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default SubcategoryPage;
