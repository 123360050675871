// src/pages/CategoryPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Category } from '../types';

const CategoryPage: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [companyName, setCompanyName] = useState<string>('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/categories`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategories(data); // Assuming the API returns the categories directly
        if (data.length > 0) {
          setCompanyName(data[0].companyName); // Assuming the response contains company name
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [companyId]);

  if (!categories.length) {
    return <h1 className="text-2xl">No Categories Found</h1>;
  }

  return (
    <div>
      <h1 className="text-4xl font-bold">{companyName}</h1>
      <h2 className="text-3xl font-bold mt-6 mb-4">Categories</h2>
      <ul className='card-list'>
        {categories.map(category => (
          <Link to={`/company/${companyId}/category/${category.id}`} key={category.id}>
            <li className="text-lg card">
              <img src={category.image} alt={category.name} className="card-image" />
              {category.name}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default CategoryPage;
