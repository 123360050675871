import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Product, Subcategory, Category, Company } from '../types';
import Register from './customer/Register';
import { useCart } from '../context/CartContext';

const ProductPage: React.FC = () => {
  const { companyId, categoryId, subcategoryId, productId } = useParams<{ companyId: string; categoryId: string; subcategoryId?: string; productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [subcategory, setSubcategory] = useState<Subcategory | null>(null);

  const { addToCart } = useCart();

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/product/${productId}`);
        if (!response.ok) {
          throw new Error('Product not found');
        }
        const data = await response.json();

        setProduct(data.product);
        setCategory(data.category);
        setCompany(data.company);

        if (subcategoryId) {
          const subcategoryResponse = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/subcategory/${subcategoryId}`);
          if (!subcategoryResponse.ok) {
            throw new Error('Subcategory not found');
          }
          const subcategoryData = await subcategoryResponse.json();
          setSubcategory(subcategoryData);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [companyId, categoryId, subcategoryId, productId]);

  const handleAddToCart = () => {
    if (product) {
      addToCart(product);
      console.log('Product added to cart:', product);
    }
  };

  if (!company) {
    return <h1 className="text-2xl">Company Not Found</h1>;
  }

  if (!category) {
    return <h1 className="text-2xl">Category Not Found</h1>;
  }

  if (subcategoryId && !subcategory) {
    return <h1 className="text-2xl">Subcategory Not Found</h1>;
  }

  if (!product) {
    return <h1 className="text-2xl">Product Not Found</h1>;
  }

  return (
    <div>
      <h1 className="text-4xl font-bold">{product.name}</h1>
      <h2 className="text-2xl font-bold mt-6">Product Details</h2>
      <div className='col-3'>
        <img width="200px" src={product.image} alt={product.name} className="card-image img-thumbnail" />
      </div>
      <p className="text-lg mt-4">Here you can add more detailed information about the product.</p>
      <p className="text-lg">Product ID: {product.id}</p>
      <p className="text-lg">Category: {category.name}</p>
      <p className="text-lg">Subcategory: {subcategory ? subcategory.name : 'N/A'}</p>
      <p className="text-lg">Company: {company.name}</p>
      
      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        onClick={handleAddToCart}
      >
        Add to Cart
      </button>
    </div>
  );
};

export default ProductPage;
