import { Link } from 'react-router-dom';
import { Cart } from 'react-bootstrap-icons';

const Header: React.FC = () => {
  return (
    <header className="flex justify-between items-center p-4 bg-gray-800 text-white">
      <h1>IT Exam Vouchers</h1>
      <nav>
        
      <ul className="flex flex-wrap space-x-4 p-4 bg-gray-800 text-white">
            <li><Link to="/" className="hover:underline">Home</Link></li>
            {/* <li><Link to="/specials" className="hover:underline">Specials!</Link></li> */}
            {/* <li><Link to="/exam" className="hover:underline">Exam</Link></li> */}
            {/* <li><Link to="/vouchers" className="hover:underline">Vouchers</Link></li> */}
            {/* <li><Link to="/practice-tests" className="hover:underline">Practice Tests</Link></li> */}
            <li><Link to="/how-it-works" className="hover:underline">How Does It Work?</Link></li>
            <li><Link to="/about-us" className="hover:underline">About Us</Link></li>
            <li><Link to="/contact-us" className="hover:underline">Contact Us</Link></li>
            {/* <li><Link to="/cart" className="hover:underline">View Cart</Link></li> */}
            {/* <li><Link to="/subscribe-save" className="hover:underline">Subscribe & Save</Link></li> */}
            <li><Link to="/cart" className="text-lg hover:underline"><Cart size={30}/>Go to Cart</Link></li>
          </ul>
      </nav>
    </header>
  );
};

export default Header;
