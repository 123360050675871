import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Order {
  _id: string;
  status: string;
  // Add other necessary fields
}

const ManageOrders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]); // Initialize orders as an empty array
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);

  // Fetch the JWT token from localStorage (now using 'token')
  const token = localStorage.getItem('token');

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [search]);

  useEffect(() => {
    fetchOrders();
  }, [page, pageSize, debouncedSearch]);

  const fetchOrders = async () => {
    try {
      const response = await fetch(`http://itexamsvouchers.com:4242/orders?page=${page}&pageSize=${pageSize}&search=${debouncedSearch}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include JWT token in Authorization header
        },
      });

      const data = await response.json();
      setOrders(data.orders || []); // Ensure orders is set to an array even if data.orders is undefined
      setTotalPages(data.pageCount || 1); // Set totalPages to 1 if undefined
    } catch (error) {
      console.error('Error fetching orders:', error);
      setOrders([]); // Fallback to empty array in case of error
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <h2>Manage Orders</h2>
      <FormControl
        type="text"
        placeholder="Search orders"
        value={search}
        onChange={handleSearch}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.length === 0 ? (
            <tr>
              <td colSpan={3}>No orders found.</td>
            </tr>
          ) : (
            orders.map(order => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.status}</td>
                <td>
                  <Link to={`/dashboard/orders/${order._id}`}>
                    <Button variant="info">View</Button>
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Pagination>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item key={index} active={index + 1 === page} onClick={() => setPage(index + 1)}>
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default ManageOrders;
