// src/pages/Home.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { data } from '../data';

const Home: React.FC = () => {
  return (
    <div>
      <h1 className="text-4xl font-bold">Not Found</h1>
    </div>
  );
};

export default Home;
