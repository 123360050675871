import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Header from '../dashboard/Header';

const AdminLayout: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle the sidebar visibility
  };

  return (
    <Container fluid className="">
      <Row className="flex-nowrap">
        <Col xs={12} className="p-0">
          <Header toggleSidebar={toggleSidebar} />
        </Col>
      </Row>

      <Row className="flex-nowrap">
        {/* Sidebar - hide on small screens unless toggled */}
        <Col
          xs={12}
          md={3}
          className={`p-0 bg-light admin-sidebar-container ${
            sidebarOpen ? 'd-block' : 'd-none d-md-block'
          }`}
        >
          <AdminSidebar toggleSidebar={toggleSidebar} />
        </Col>

        {/* Main content */}
        <Col xs={12} md={9} className="p-4 content-area">
          <main>
            <Outlet /> {/* Render child routes here */}
          </main>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminLayout;
