import React from 'react';
import { Link } from 'react-router-dom';

interface AdminSidebarProps {
  toggleSidebar: () => void; // Function to close the sidebar
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({ toggleSidebar }) => {
  return (
    <div className="admin-sidebar bg-gray-800 text-white min-vh-100">
      <div className="p-4 text-xl font-bold border-b border-gray-700">
        Admin Panel
      </div>
      <ul className="mt-4 p-0">
        <li className="mb-2">
          <Link
            to="/dashboard/manage-products"
            className="block py-2 px-4 rounded hover:bg-gray-700 hover:text-yellow-500 transition duration-300 text-decoration-none"
            onClick={toggleSidebar} // Close the sidebar when clicked
          >
            Manage Products
          </Link>
        </li>
        <li className="mb-2">
          <Link
            to="/dashboard/manage-orders"
            className="block py-2 px-4 rounded hover:bg-gray-700 hover:text-yellow-500 transition duration-300 text-decoration-none"
            onClick={toggleSidebar} // Close the sidebar when clicked
          >
            Manage Orders
          </Link>
        </li>
        {/* Add more links as needed */}
      </ul>
    </div>
  );
};

export default AdminSidebar;
