import React from 'react';

const HowitWorks = () => {
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">How Does It Work?</h1>
      <h3 className="text-center mb-5">Saving money on your next exam is as easy as 1 2 3!</h3>

      <div className="text-center mb-4">
        <img src="../image/circle1.png" alt="Step 1" className="bigbullets" />
        <p>Purchase an exam voucher from us at a price much lower than the full price of your exam.</p>
      </div>
      
      <div className="text-center mb-4">
        <img src="../image/circle2.png" alt="Step 2" className="bigbullets" />
        <p>Receive your voucher in your email within 1 business day, usually within a few hours. International candidates skip steps 2 & 3 since ITExamVouchers.com provides the registration.</p>
      </div>
      
      <div className="text-center mb-4">
        <img src="../image/circle3.png" alt="Step 3" className="bigbullets" />
        <p>Use the voucher as payment for your exam instead of paying the full price when scheduling your exam over the internet, by phone, or at the testing center.</p>
      </div>

      <h3 className="overline text-center my-4">Testimonials</h3>
      <p className="text-center">We encourage our customers to rate our service after they receive their vouchers. 
        <a href="../pages/section.php?section=%2BG8%3D&amp;topic=Testimonials" className="text-primary"> See what others are saying about their experiences with ITExamVouchers.com.</a>
      </p>

      <h3 className="overline text-center my-4">Frequently Asked Questions</h3>
      <p className="text-center">
        <a href="../pages/section.php?section=%2BGw%3D&amp;topic=Frequently%20Asked%20Questions" className="text-primary">View answers to some of our most common questions.</a>
      </p>

      <h3 className="overline text-center my-4">We Can Schedule Your Exam For You!</h3>
      <p className="text-center">In addition to selling vouchers, we are also an authorized Prometric and VUE testing center, which means that we can schedule your exam at any testing center in the United States or Canada for free! 
        <a href="https://itexamvouchers.com/pages/section.php?section=%2BG0%3D" className="text-primary"> Click here to learn more about our free exam registration service.</a>
      </p>

      <h3 className="overline text-center my-4">Order Policies</h3>
      <div className="mb-4">
        <p><strong>Tax and Shipping Fees</strong><br />No tax or shipping fees will be added to the orders.</p>
        <p><strong>Voucher Expiration</strong><br />All exam vouchers purchased from ITExamVouchers.com must be used to schedule the exam, and the exam must be completed by the designated expiration date of each exam test voucher.</p>
        <p><strong>Order Confirmation</strong><br />Upon receipt of your order, ITExamVouchers.com will send you an order confirmation email within a few minutes. Next, the exam test vouchers will be emailed within one business day (usually a few hours). If you requested test registration with your order or placed an international order, the exam registration will be confirmed in this email as well.</p>
        <p><strong>Prices</strong><br />All test voucher prices are subject to change without notice. Price changes are not retroactive.</p>
        <p><strong>Voucher Specials</strong><br />Exam test vouchers on special must be used to schedule the exam immediately due to the short life of the test voucher. Exams must be scheduled and completed by the expiration date of the exam test voucher. All sales on exam test vouchers on special are final. Exam test vouchers on special are not refundable.</p>
        <p><strong>Refund Policy</strong><br />We are proud to be the only company in the world that offers its customers a refund or exchange option for long-term exam vouchers. Refunds can be requested from us within thirty (30) days from the order date, provided the test voucher has not been sent to you already or a test has not been scheduled. Partial refunds can be requested for exams that are scheduled within thirty (30) days from the order date. For the partial refunds, the regular price of any completed and/or scheduled test will be subtracted. All refunds are subject to a 15% administration fee. There will be no refund available once the voucher numbers have been emailed to you. This refund policy only applies to long-term vouchers. All sales are final on short-term exam vouchers.</p>
      </div>

      <div className="text-center my-4">
        <ul className="list-inline">
          <li className="list-inline-item"><a href="section.php?section=%2BG8%3D&amp;topic=Testimonials" className="text-primary">Testimonials</a></li>
          <li className="list-inline-item"><a href="section.php?section=%2BGw%3D&amp;topic=Frequently Asked Questions" className="text-primary">Frequently Asked Questions</a></li>
          <li className="list-inline-item"><a href="section.php?section=%2BG0%3D&amp;topic=Free Exam Scheduling" className="text-primary">Free Exam Scheduling</a></li>
        </ul>
      </div>
    </div>
  );
};

export default HowitWorks;
