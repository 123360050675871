import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

// Check if the user is authenticated (JWT token exists in localStorage)
const isAuthenticated = () => {
  return !!localStorage.getItem('token'); // Adjust if you're using another method to store the token
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  return <>{children}</>; // If authenticated, render the children (protected content)
};

export default ProtectedRoute;
