import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useParams, useNavigate } from 'react-router-dom';

interface Product {
  id: string;
  name: string;
  image: string;
  price: number;
}

const ManageCategoryProducts: React.FC = () => {
  const { companyId, categoryId } = useParams<{ companyId: string; categoryId: string }>();
  const [products, setProducts] = useState<Product[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productToDelete, setProductToDelete] = useState<Product | null>(null);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [newProductMode, setNewProductMode] = useState(false);

  const navigate = useNavigate(); // To handle the back button functionality
  
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/products`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [companyId, categoryId]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleEdit = (product: Product) => {
    setSelectedProduct(product);
    setUpdatedName(product.name);
    setUpdatedImage(product.image);
    setUpdatedPrice(product.price ? product.price.toFixed(2) : '');
    setNewProductMode(false); // Switch to edit mode
    setShowModal(true);
  };

  const handleAddProduct = () => {
    setSelectedProduct(null);
    setUpdatedName('');
    setUpdatedImage('');
    setUpdatedPrice('');
    setNewProductMode(true); // Switch to add mode
    setShowModal(true);
  };

  const handleDelete = (product: Product) => {
    setProductToDelete(product);
    setShowDeleteModal(true); // Show confirmation modal for delete
  };

  const confirmDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/products/${productToDelete.id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setProducts(products.filter(product => product.id !== productToDelete.id));
        setShowDeleteModal(false); // Close the modal after delete
      } else {
        console.error('Failed to delete the product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSaveChanges = async () => {
    if (newProductMode) {
      const newProduct = {
        id: `product-${Math.random().toString(36).substr(2, 9)}`, // Generate random ID
        name: updatedName,
        image: updatedImage,
        price: parseFloat(updatedPrice),
      };

      try {
        const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
          body: JSON.stringify(newProduct),
        });

        if (response.ok) {
          setProducts([...products, newProduct]);
          setShowModal(false); // Close modal after adding
        } else {
          console.error('Failed to add product');
        }
      } catch (error) {
        console.error('Error adding product:', error);
      }
    } else if (selectedProduct) {
      try {
        const response = await fetch(`http://itexamsvouchers.com:4242/company/${companyId}/category/${categoryId}/products/${selectedProduct.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
          body: JSON.stringify({
            name: updatedName,
            image: updatedImage,
            price: parseFloat(updatedPrice) || 0, // Ensure price is numeric
          }),
        });

        if (response.ok) {
          setProducts(products.map(product =>
            product.id === selectedProduct.id ? { ...product, name: updatedName, image: updatedImage, price: parseFloat(updatedPrice) || 0 } : product
          ));
          setShowModal(false); // Close modal after editing
        } else {
          console.error('Failed to update product');
        }
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
  };

  const handleClose = () => setShowModal(false);

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <Button className="bi bi-arrow-left-circle-fill" onClick={handleBack}>
          <ArrowLeft display="inline" />
        </Button>
        <h2 className="ms-2">Manage Products</h2>
      </div>
      <Button variant="primary" onClick={handleAddProduct}>Add Product</Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(product)}>Edit</Button>
                <Button variant="danger" onClick={() => handleDelete(product)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Edit/Add Product Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{newProductMode ? 'Add Product' : 'Edit Product'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formProductName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formProductImage">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                value={updatedImage}
                onChange={(e) => setUpdatedImage(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formProductPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                value={updatedPrice}
                onChange={(e) => setUpdatedPrice(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            {newProductMode ? 'Add Product' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {productToDelete?.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageCategoryProducts;
