import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import SubcategoryPage from './pages/SubcategoryPage';
import CategoryPage from './pages/CategoryPage';
import CategorySubcategoryPage from './pages/CategorySubcategoryPage';
import ProductPage from './pages/ProductPage';
import NotFound from './pages/NotFound';
import Login from './pages/admin/Login';
import Dashboard from './pages/dashboard/Dashboard';
import ManageProducts from './pages/dashboard/components/ManageProducts';
import ManageCategories from './pages/dashboard/components/ManageCategories';
import ManageCategoryProducts from './pages/dashboard/components/ManageCategoryProducts';
import CartPage from './pages/CartPage';
import Header from './pages/Header';
import PlaceOrder from './pages/customer/PlaceOrder';
import ManageOrders from './pages/dashboard/ManageOrdersPage';
import OrderDetails from './pages/dashboard/OrderDetails';
import AdminLayout from './pages/layouts/AdminLayout';
import UserLayout from './pages/layouts/UserLayout';
import ProtectedRoute from './pages/dashboard/components/ProtectedRoute';
import Logout from './pages/admin/Logout';
import HowitWorks from './pages/HowitWorks';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <main className="">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path='/how-it-works' element={<HowitWorks/>}/>
            <Route element={<UserLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/place-order" element={<PlaceOrder />} />
              <Route path="/company/:companyId" element={<CategoryPage />} />
              <Route path="/company/:companyId/category/:categoryId" element={<SubcategoryPage />} />
              <Route path="/company/:companyId/category/:categoryId/product/:productId" element={<ProductPage />} />
              
              <Route path="*" element={<NotFound />} />
            </Route>

            {/* Admin Routes */}
            <Route element={<AdminLayout />}>
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/dashboard/manage-products" element={<ProtectedRoute><ManageProducts /></ProtectedRoute>} />
              <Route path="/dashboard/manage-products/:companyId" element={<ProtectedRoute><ManageCategories /></ProtectedRoute>} />
              <Route path="/dashboard/manage-products/:companyId/category/:categoryId" element={<ProtectedRoute><ManageCategoryProducts /></ProtectedRoute>} />
              <Route path="/dashboard/manage-orders" element={<ProtectedRoute><ManageOrders /></ProtectedRoute>} />
              <Route path="/dashboard/orders/:orderId" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
            </Route>

            {/* <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/place-order" element={<PlaceOrder />} />
            <Route path="/company/:companyId" element={<CategoryPage />} />
            <Route path="/company/:companyId/category/:categoryId" element={<SubcategoryPage />} />
            <Route path="/company/:companyId/category/:categoryId/subcategory/:subcategoryId" element={<CategorySubcategoryPage />} />
            <Route path="/company/:companyId/category/:categoryId/product/:productId" element={<ProductPage />} />
            <Route path="/company/:companyId/category/:categoryId/subcategory/product/:productId" element={<ProductPage />} />
            <Route path="/company/:companyId/category/:categoryId/subcategory/:subcategoryId/product/:productId" element={<ProductPage />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/manage-products" element={<ManageProducts />} />
            <Route path="/dashboard/manage-products/:companyId" element={<ManageCategories />} />
            <Route path="/dashboard/manage-products/:companyId/category/:categoryId" element={<ManageCategoryProducts />} />

            <Route path="/dashboard/manage-orders" element={<ManageOrders />} />
            <Route path="/dashboard/orders/:orderId" element={<OrderDetails />} />
            <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
