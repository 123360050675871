import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';

interface Order {
  _id: string;
  status: string;
  cart: {
    _id: string;
    name: string;
    image: string;
  }[];
  // Add other necessary fields
} 

// Fetch the JWT token from localStorage (now using 'token')
const token = localStorage.getItem('token');

const OrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = async () => {
    const response = await fetch(`http://itexamsvouchers.com:4242/orders/${orderId}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include JWT token in Authorization header
      }
    });
    const data = await response.json();
    setOrder(data);
  };

  const handleStatusChange = async (status: string) => {
    await fetch(`http://itexamsvouchers.com:4242/orders/${orderId}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include JWT token in Authorization header
      },
      body: JSON.stringify({ status }),
    });
    fetchOrderDetails();
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Order Details</h2>
      <p>Status: {order.status}</p>
      <Button onClick={() => handleStatusChange('paid')} disabled={order.status === 'paid'}>
        Mark as Paid
      </Button>
      <Button onClick={() => handleStatusChange('refunded')} disabled={order.status === 'refunded'}>
        Mark as Refunded
      </Button>
      <Button onClick={() => handleStatusChange('delivered')} disabled={order.status === 'delivered'}>
        Mark as Delivered
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {order.cart.map(item => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td><img src={item.image} alt={item.name} width="100" /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OrderDetails;
