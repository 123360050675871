import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu); // Toggle profile menu visibility
  };

  return (
    <header className="flex justify-between items-center p-4 bg-gray-800 text-white">
      <div className="flex items-center">
        {/* Sidebar toggle button for mobile view */}
        <button
          className="md:hidden mr-4 text-white bg-gray-700 px-3 py-2 rounded"
          onClick={toggleSidebar}
        >
          ☰ {/* You can replace this with an icon */}
        </button>

        <h1 className="text-xl">Dashboard</h1>
      </div>

      <div className="relative">
        <button onClick={toggleProfileMenu} className="flex items-center space-x-2">
          <img
            src="https://via.placeholder.com/40"
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <span>Vishnu</span> {/* Replace with actual user name */}
        </button>

        {/* Profile dropdown menu */}
        {showProfileMenu && (
          <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg py-2">
            <Link to="/profile" className="block px-4 py-2 hover:bg-gray-200">Profile</Link>
            <Link to="/logout" className="block px-4 py-2 hover:bg-gray-200">Logout</Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
